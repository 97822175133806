import { cn } from "@/src/utils/tailwindUtils";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { CircularProgress } from "@mui/material";

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "bg-primary text-primary-foreground hover:brightness-125",
				destructive:
					"bg-destructive text-destructive-foreground hover:bg-destructive/90",
				outline:
					"border border-input bg-transparent hover:bg-accent hover:text-accent-foreground",
				secondary:
					"bg-secondary text-secondary-foreground hover:bg-secondary/80",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				magic:
					"bg-gradient-to-tr from-indigo-600 via-pink-500 to-yellow-400 hover:brightness-75",
				link: "text-primary underline-offset-4 hover:underline",
			},
			size: {
				default: "h-10 px-4 py-2",
				sm: "h-9 rounded-md px-3",
				lg: "h-11 rounded-md px-8",
				icon: "h-10 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	isLoading?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	fullWidth?: boolean;
	align?: "left" | "center" | "right";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			variant,
			size,
			asChild = false,
			isLoading,
			startIcon,
			endIcon,
			fullWidth,
			align = "center",
			children,
			...props
		},
		ref,
	) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(
					buttonVariants({ variant, size, className }),
					fullWidth ? "w-full" : "",
					"relative",
					{
						"justify-start": align === "left",
						"justify-center": align === "center",
						"justify-end": align === "right",
					},
				)}
				ref={ref}
				disabled={props.disabled || isLoading}
				{...props}
			>
				<>
					{isLoading && (
						<span className="absolute inset-0 flex items-center justify-center">
							<CircularProgress size={20} className="text-foreground" />
						</span>
					)}
					<span
						className={cn(
							isLoading ? "opacity-0" : "flex flex-row items-center",
						)}
					>
						{startIcon && <span className="mr-2">{startIcon}</span>}
						{children}
						{endIcon && <span className="ml-2">{endIcon}</span>}
					</span>
				</>
			</Comp>
		);
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
