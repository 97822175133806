"use client";

import LoadingSpinner from "@/components/ui/custom/LoadingSpinner";
import { User } from "firebase/auth";
import { useRouter } from "next/navigation";
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { getIsAuthorized, userCanSeeSeriesButton } from "../lib/authorization";
import { authClient } from "../utils/firebase/firebaseClientConfig";
import {
	checkIsShortbreadArtist,
	fetchIsAdminUser,
	fetchIsUserCreatorOfComic,
	setUserUidField,
} from "../utils/firebase/userOperations";

interface AuthContextType {
	user: User | null;
	isAdmin: boolean;
	isShortbreadArtist: boolean;
	isAuthorized: boolean;
	canSeeSeriesButton: boolean;
	isCreatorOfAnyComics: boolean;
	loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
	user: null,
	loading: true,
	isAdmin: false,
	isShortbreadArtist: false,
	isAuthorized: false,
	canSeeSeriesButton: false,
	isCreatorOfAnyComics: false,
});

export function AuthProvider({ children }: { children: ReactNode }) {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isShortbreadArtist, setIsShortbreadArtist] = useState(false);
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [canSeeSeriesButton, setCanSeeSeriesButton] = useState(false);
	const [isCreatorOfAnyComics, setIsCreatorOfAnyComics] = useState(false);

	useEffect(() => {
		return authClient.onIdTokenChanged(async (user) => {
			if (user) {
				setUser(user);
				setUserUidField(user.uid);
				setIsAdmin(await fetchIsAdminUser(user.uid));
				setIsShortbreadArtist(await checkIsShortbreadArtist(user.uid));
				setIsAuthorized(await getIsAuthorized(user.uid));
				setCanSeeSeriesButton(await userCanSeeSeriesButton(user.uid));
				setIsCreatorOfAnyComics(await fetchIsUserCreatorOfComic(user.uid));
				setLoading(false);
			}
		});
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user,
				loading,
				isAdmin,
				isShortbreadArtist,
				isAuthorized,
				canSeeSeriesButton,
				isCreatorOfAnyComics,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export const useAuth = () => useContext(AuthContext);

export function withAuth<P extends object>(
	WrappedComponent: React.ComponentType<P & { user: User }>,
) {
	return function WithAuth(props: Omit<P, "user">) {
		const { user, loading } = useAuth();
		const router = useRouter();

		useEffect(() => {
			if (!loading && !user) {
				router.push("/login");
			}
		}, [user, loading, router]);

		if (loading || !user) {
			return <LoadingSpinner />;
		}

		return <WrappedComponent {...(props as P)} user={user} />;
	};
}
